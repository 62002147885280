import React from 'react';

import Layout from '../../layouts';
import PageTitle from '../../components/Subpage/title';
import Container from '../../components/Container';
import Register from '../../components/Register';

const page = { name: 'Create an Account', slug: 'account/register' };

export default function RegisterPage({ location }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout title={page.name} location={`/${page.slug}/`} prevPage={prevPage}>
      <PageTitle title={page.name} />
      <Container>
        <Register />
      </Container>
    </Layout>
  );
}
